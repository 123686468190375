import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-delete.msg';

const ConfirmDelete = ({ onCancel, onConfirm, progress }) => {
  const recurring = useSelector(state => state.bkf.get('recurring'));
  const [deleteSeries, setDeleteSeries] = useState(false);

  const handleSeriesChange = (ev) => setDeleteSeries(ev.target.checked);
  const handleConfirm = () => onConfirm({ deleteSeries });

  return (
    <div className={progress ? 'disabled' : ''}>
      <p>
        <strong>{txt(msg.txtConfirmDelete)}</strong>
      </p>

      {recurring && (
        <>
          <p className="text-info mb2">
            <i className="fa fa-exclamation-circle mr1" />
            Bokningen ingår i en serie
          </p>
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={deleteSeries} onChange={handleSeriesChange} />
              Radera hela serien
            </label>
          </div>
        </>
      )}

      <br />
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={4} onClick={onCancel}>{txt(msg.btnCancel)}</button> &nbsp;
        <button type="submit" className="btn-delete" tabIndex={3} onClick={handleConfirm}>{progress ? txt(msg.btnDeleting) : txt(msg.btnConfirm)}</button>
      </div>
    </div>
  );
};

export default ConfirmDelete;
